<template>
    <div class="footer-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Kontakt</h3>

                        <ul class="footer-contact-info">
	                          <!--
                            <li>
                                <i class="flaticon-phone-call"></i>
                                <span>Montag - Freitag : 10:00 - 16:00</span>
                                <a href="tel:+4987120549040">
                                  +49 871 205490-40
                                </a>
                            </li>
                            -->
                            <li>
                                <i class="flaticon-email"></i>
                                <span>Schreiben Sie uns per Mail</span>
                                <a href="mailto:info@cftools.software">info@cftools.software</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Unser Netzwerk</h3>

                        <ul class="footer-quick-links">
                            <li>
                              <a href="https://link-landshut.de" target="_blank">
                                LINK Landshut
                                <small>
                                  <i class="fas fa-external-link-alt"/>
                                </small>
                              </a>
                            </li>
                          <li>
                            <a href="https://cftools.com" target="_blank">
                              CFTools Cloud
                              <small>
                                <i class="fas fa-external-link-alt"/>
                              </small>
                            </a>
                          </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="copyright-area">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <p>&copy; {{new Date().getFullYear()}} CFTools™ Software GmbH. Alle Rechte vorbehalten.</p>
                    </div>

                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <ul>
                            <li><router-link to="/imprint">Impressum</router-link></li>
                            <li><router-link to="/privacy">Datenschutz</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
       name: 'Footer'
    }
</script>