<template>
    <div :class="['navbar-area', {'is-sticky': isSticky}]">
        <div class="bionix-nav">
            <div class="container">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <router-link class="navbar-brand" to="/">
                        <img src="../../assets/images/logo.png" alt="logo" width="130">
                    </router-link>

                    <b-navbar-toggle target="navbarSupportedContent">
                        <span class="icon-bar top-bar"></span>
                        <span class="icon-bar middle-bar"></span>
                        <span class="icon-bar bottom-bar"></span>
                    </b-navbar-toggle>

                    <b-collapse class="collapse navbar-collapse" id="navbarSupportedContent" is-nav>
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <router-link to="/" class="nav-link">Home</router-link>
                            </li>

                            <!--
                            <li class="nav-item">
                                <router-link to="/projects" class="nav-link">
                                  Projekte <i class="fas fa-chevron-down"></i>
                                </router-link>

                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/projects/cftools-cloud" class="nav-link">CFTools Cloud</router-link>
                                    </li>
                                </ul>
                            </li>
                            -->

                            <li class="nav-item">
                                <router-link to="/contact" class="nav-link">
                                  Kontakt
                                </router-link>
                            </li>

                            <li class="nav-item">
                              <router-link to="/jobs" class="nav-link">
                                Jobs
                              </router-link>
                            </li>
                        </ul>
                    </b-collapse>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Navbar',
    components: {

    },
    data(){
        return {
            isSticky: false
        }
    },

    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                that.isSticky = true
            } else {
                that.isSticky = false
            }
        })
    },

    computed: {

    },

    methods: {

    }
}
</script>